import {
	Box,
	Button,
	Flex,
	Image,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	Text,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FcGoogle } from 'react-icons/fc';
import loginBanner from '../../assets/login/one.png';
import logo from '../../assets/navbar/blue-logo.svg';
import { AuthContext } from '../../context/Auth.Context';
import { getGoogleRedirectUrl } from '../../services/requests';

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [googleAuthUrl, setGoogleAuthUrl] = useState('');

	const getUrl = async () => {
		const res = await getGoogleRedirectUrl();
		setGoogleAuthUrl(res.data);
	};

	const [show, setShow] = useState(false);
	const handleClick = () => setShow(!show);

	useEffect(() => {
		getUrl();
	}, []);

	const { userLoading, loginWithEmailAndPassword } = useContext(AuthContext);

	const handelSubmit = async (e) => {
		e.preventDefault();

		const payload = {
			email,
			password,
		};
		await loginWithEmailAndPassword(payload);
	};

	return (
		<Flex direction={{ base: 'column', lg: 'row' }}>
			<Flex
				align="center"
				justify="center"
				w={{ base: '100%', lg: '50%' }}>
				<Box
					position="relative"
					w={{ base: '100%', lg: '50%' }}
					p="8"
					borderRadius="5"
					textAlign="center">
					<Flex m="auto" w="50%" justify="center" align="center">
						<Image alt="logo" src={logo} />
					</Flex>
					<Text mt="1.5rem" pb="1.5rem" color="#b3b3b3">
						Welcome back to Niwi CMS!
					</Text>
					{/* <a href={googleAuthUrl}>
						<Button
							variant={'outline'}
							my="1.5rem"
							colorScheme="primary"
							shadow="primaryBtn"
							type="submit">
							Login with{' '}
							<Text ps="0.5rem" as={'span'}>
								<FcGoogle fontSize={'1.5rem'} />
							</Text>
						</Button>{' '}
					</a>
					<Text fontWeight={'bold'} fontSize="lg" pb="1.5rem">
						OR
					</Text> triggering deployment */}
					<form onSubmit={handelSubmit}>
						<Flex flexDir={'column'} gap={'1rem'}>
							<Input
								isRequired
								borderRadius={'lg'}
								variant="outline"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder="enter email"
								type="email"
							/>

							{/* <Input
								placeholder="enter password"
								isRequired
								variant="outline"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								type="password"
							/> */}
							<InputGroup size="md">
								<Input
									borderRadius={'lg'}
									placeholder="enter password"
									pr="4.5rem"
									type={show ? 'text' : 'password'}
									isRequired
									variant="outline"
									value={password}
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>
								<InputRightElement width="3rem">
									<Flex
										cursor={'pointer'}
										fontSize={'1.5rem'}
										onClick={handleClick}>
										{show ? (
											<AiOutlineEye />
										) : (
											<AiOutlineEyeInvisible />
										)}
									</Flex>
								</InputRightElement>
							</InputGroup>
						</Flex>
						<Button
							isLoading={userLoading}
							bgGradient="linear(to-r, #3a5ef0 , #0f2daa )"
							my={5}
							width="35%"
							colorScheme="primary"
							shadow="primaryBtn"
							type="submit">
							<b>Log in</b>
						</Button>{' '}
					</form>
					<Text
						display={{ base: 'block', lg: 'none' }}
						align="center"
						justifySelf="flex-end"
						marginTop="3rem">
						Product of{' '}
						<b>
							<Link href="https://Apric.in" isExternal>
								Apric
							</Link>
						</b>
					</Text>
				</Box>
			</Flex>
			<Flex
				overflow="hidden"
				position="relative"
				h={{ base: '50vh', lg: '100vh' }}
				w={{ base: '100%', lg: '50%' }}
				// h={{ base: "100%", md: "50%" }}
			>
				<Image
					height="100%"
					width="100%"
					objectFit="cover"
					src={loginBanner}
					alt="webx logo"
				/>
				<Text
					display={{ base: 'none', lg: 'block' }}
					position="absolute"
					bottom="0"
					left="50%"
					transform="translate(-50%, 0%)"
					align="center"
					justifySelf="flex-end"
					padding="1rem">
					Product of{' '}
					<b>
						<Link href="https://Apric.in" isExternal>
							Apric
						</Link>
					</b>
				</Text>
			</Flex>
		</Flex>
	);
};

export default Login;
