import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { BsBook } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';
import { FiInbox } from 'react-icons/fi';
import { TbLayoutDashboard } from 'react-icons/tb';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../../assets/navbar/apric-logo.svg';
import launch from '../../assets/sidebar/launch.svg';

const sidebarOptions = [
	{
		title: 'Dashboard',
		icon: <TbLayoutDashboard />,
		route: '/dashboard',
		disable: false,
	},
	// {
	// 	title: 'Pages',
	// 	icon: <HiOutlineDocumentDuplicate />,
	// 	route: '/pages',
	// 	disable: true,
	// },
	{
		title: 'Product',
		icon: <FiInbox />,
		route: '/website-products',
		disable: false,
	},
	{
		title: 'Blogs',
		icon: <FaRegEdit />,
		route: '/blogs-list',
		disable: false,
	},
	// {
	// 	title: 'Analytics',
	// 	icon: <VscGraph />,
	// 	route: '/website-analytics',
	// 	disable: true,
	// },
	// {
	// 	title: 'Leads',
	// 	icon: <BsPeople />,
	// 	route: '/leads',
	// 	disable: false,
	// },
	{
		title: 'Bookings',
		icon: <BsBook />,
		route: '/bookings',
		disable: false,
	},
];

const Sidebar = () => {
	const navigate = useNavigate();

	const navigateTo = async (route, disabled) => {
		if (!disabled) {
			await navigate(route);
		}
	};
	return (
		<Flex
			justify={'space-between'}
			flexDir={'column'}
			h="100vh"
			bg="secondary.500">
			<Flex gap={'0.5rem'} flexDir={'column'} p="1rem">
				<Flex
					align={'center'}
					justify="center"
					gap={'0.5rem'}
					borderRadius="4px"
					p="0.5rem 1rem">
					<Box py="2rem" w="2.5rem">
						<Image alt="apric logo" src={logo} />
					</Box>
				</Flex>
				<Flex gap={'0.5rem'} flexDir={'column'}>
					{sidebarOptions?.map(
						({ title, icon, route, disable }, i) => (
							<NavLink
								key={i}
								className={({ isActive }) =>
									isActive
										? 'activeSidebar'
										: 'inActiveSidebar'
								}
								onClick={() => navigateTo(route, disable)}
								to={route}>
								<Button
									isDisabled={disable}
									display={'flex'}
									flexDir="column"
									w="100%"
									h="auto"
									p="0.5rem"
									gap="0.5rem"
									bg={'transparent'}>
									<Box fontSize={'1.5rem'}>{icon}</Box>
									<Text fontSize={'xs'}>{title}</Text>
								</Button>
							</NavLink>
						),
					)}
				</Flex>
			</Flex>
			<Flex
				gap={'0.5rem'}
				p="1rem"
				mb="2rem"
				align={'center'}
				justify="center">
				<Image alt="launch" src={launch} />
			</Flex>
		</Flex>
	);
};

export default Sidebar;
